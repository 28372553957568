<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<div>
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">售后编号:</label>
					<el-input
						class="input-item"
						style="margin-left:5px;width:250px"
						v-model="searchData.afterSaleCode"
						@keyup.enter.native="search"
						placeholder="请输入售后编号"
					></el-input>
				</div>
				<div class="filter-item">
					<label class="label">订单编号:</label>
					<el-input
						class="input-item"
						style="margin-left:5px;width:250px"
						v-model="searchData.orderNo"
						@keyup.enter.native="search"
						placeholder="请输入订单编号"
					></el-input>
				</div>
				<div class="filter-item">
					<label class="label">商品名称:</label>
					<el-input
						class="input-item"
						style="margin-left:5px;width:250px"
						v-model="searchData.goodsName"
						@keyup.enter.native="search"
						placeholder="请输入商品名称"
					></el-input>
				</div>
			</div>
			<div>
				<!-- 退款类型 -->
				<div class="filter-item">
					<label class="label">售后状态:</label>
					<el-select class="option-item" v-model="searchData.status" placeholder="请选择">
						<el-option label="全部" :value="null"></el-option>
						<el-option
							v-for="item in typeOptions"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</div>
				<!-- 时间筛选 -->
				<div class="filter-item">
					<label class="label">申请时间:</label>
					<el-date-picker
						v-model="searchTime"
						type="datetimerange"
						range-separator="～"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						align="right"
						value-format="yyyy-MM-dd HH:mm:ss"
					></el-date-picker>
				</div>
			</div>
			<div>
				<!-- button -->
				<div class="filter-item">
					<el-button type="primary" style="width:100px;" @click="search">查询</el-button>
					<el-button type="primary" style="width:100px;margin-right:20px;" @click="reset">重置</el-button>
					<button-permissions :datas="'refundAfterReplaceExport'">
						<el-button type="primary" style="width:100px;" @click="groupExport" :loading='exportLoading'>导出</el-button>
					</button-permissions>
				</div>
			</div>
		</div>
		<!-- tab切换 -->
		<el-tabs v-model="searchData.searchType" @tab-click="search">
			<el-tab-pane
				v-for="item in listStateList"
				:key="item.id"
				:label="item.value + (item.num?'('+item.num+')':'')"
				:name="item.id+''"
			></el-tab-pane>
		</el-tabs>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column label="商品" width="320">
					<template slot-scope="scope">
						<div>售后单号：{{scope.row.afterSaleCode}}</div>
						<div style="display:flex;padding-top: 10px;">
							<img
								:src="scope.row.skuImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'"
								alt
								style="width:60px;height:60px;"
							/>
							<div style="margin-left: 10px;">
								<div>{{scope.row.goodsName}}</div>
								<div>换货数量：{{scope.row.quantity}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="售后方式" width="280">
					<template slot-scope="scope">
						<div>订单号：{{scope.row.mainOrderNo}}</div>
						<div>申请时间：{{scope.row.createTime}}</div>
						<div>换货</div>
					</template>
				</el-table-column>
				<el-table-column label="买家/收货人" width="280">
					<template slot-scope="scope">
						<div>买家手机号：{{scope.row.userPhone}}</div>
						<div>收货人姓名：{{scope.row.receiverName}}</div>
						<div>收货人手机号：{{scope.row.receiverPhone}}</div>
					</template>
				</el-table-column>
				<el-table-column label="金额" width="165">
					<template slot-scope="scope">
						<div>
							<div style="display: flex;">
								<div style="width: 70px;text-align: right;">商品总额：</div>
								¥{{scope.row.realAmount}}
							</div>
							<div style="display: flex;">
								<div style="width: 70px;text-align: right;">优惠：</div>
								¥{{scope.row.discountAmount}}
							</div>
							<div style="display: flex;">
								<div style="width: 70px;text-align: right;">运费：</div>
								¥{{scope.row.freightAmount}}
							</div>
							<div style="display: flex;">
								<div style="width: 70px;text-align: right;">实收金额：</div>
								¥{{scope.row.payAmount}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="售后状态" width="100">
					<template slot-scope="scope">
						<div>{{typeOptions[scope.row.status - 1].label}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="170">
					<template slot-scope="scope">
						<button-permissions :datas="'refundAfterReplaceToDo'">
							<div class="table-button" @click="handleDetailShow(scope.row)">{{(scope.row.status == 7 || scope.row.status == 8)?'查看详情':'去处理'}}</div>
						</button-permissions>
						<button-permissions :datas="'refundAfterOrderBack'" v-if="scope.row.status == 8">
							<div v-if="!scope.row.isRollback" class="table-button" @click="handleAfterBack(scope.row)">回滚</div>
							<div v-else class="table-button" style="color: #FF4D51;" >已回滚</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination
				v-if="page.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import apiList from '@/api/other'
import {
	afterSaleBack
} from '@/api/wyUsedInterface'
import buttonPermissions from '@/components/buttonPermissions';
export default {
    name:'refundAfterReplace',
	components: {
		buttonPermissions
	},
	data () {
		return {
			searchTime: '',  //时间
			searchData: {
				afterSaleCode: '',    //售后单号
				goodsCode: '',  //商品编码
				orderNo:'',//订单编号
				goodsName: '',  //商品名称
				startDate: '',  //开始时间
				endDate: '',    //结束时间
				applyReason: null, //申请原因
				cargoStatus: null,  //发货状态
				status: null,    //售后状态: 1、待审单 2、待审核  3、待下发售后地址 4、待买家发货 5、待卖家收货 6、待打款 7、售后成功 8、售后关闭 9、待商家发货
				minRefundMoney: null,    //最小退款金额
				maxRefundMoney: null,    //最小退款金额
				searchType: '0', //订单类型
			},
            loading: false,
            exportLoading:false,    //导出按钮加载状态
			listStateList: [
                {
					id: 0,
					value: '全部订单',
					num:0,
				},
				{
					id: 1,
					value: '待换货处理',
					num: 0,
				},
				{
					id: 2,
					value: '换货待买家寄出',
					num: 0,
				},
				{
					id: 3,
					value: '商家待确认',
					num: 0,
				},
				{
					id: 4,
					value: '已完成',
					num: 0,
				}, {
					id: 5,
					value: '已拒绝',
					num: 0,
				}
			],
			typeOptions: [{
				value: 1,
				label: '待客服审单'
			}, {
				value: 2,
				label: '待客服审核'
			}, {
				value: 3,
				label: '待下发售后地址'
			}, {
				value: 4,
				label: '待买家发货'
			}, {
				value: 5,
				label: '待卖家收货'
			}, {
				value: 6,
				label: '待打款'
			}, {
				value: 7,
				label: '售后成功'
			}, {
				value: 8,
				label: '售后关闭'
			}, {
				value: 9,
				label: '待商家发货'
			}],
			reasons: [],     //申请原因列表
			shipOptions: [{
				value: 2,
				label: '未发货'
			}, {
				value: 1,
				label: '已发货'
			}],
			tableData: [],
			page: {
				total: 0,
				pageNo: 1,
				size: 20
			},
		}
	},
	async beforeMount () {
        // console.log(1233);
		this.getList();
	},
	computed: {},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true
			try {
				let data = this.searchData;
				// console.log(data);
				data.pageNo = this.page.pageNo;
				data.pageSize = this.page.size;
				data.startDate = '';
				data.endDate = '';
				if (this.searchTime) {
					data.startDate = this.searchTime[0];
					data.endDate = this.searchTime[1];
				}
				let data1 = JSON.parse(JSON.stringify(data));
				let result = '';
				
                result = await apiList.ordersupportExchangeIndex(data1);
				
				this.tableData = result.data.records;
				this.page.total = result.data.total;
				//获取各个状态的数量
                let result1 = await apiList.afterSaleExchangeCount(data);
                if (result1.success) {
					var totalnum = result1.data.waitTrailNums+result1.data.waitBuyerDeliverNums+result1.data.waitSellerDeliveryNums+result1.data.successNums+result1.data.refuseNums;
					this.listStateList[0].num = totalnum||0;
                    this.listStateList[1].num = result1.data.waitTrailNums;
                    this.listStateList[2].num = result1.data.waitBuyerDeliverNums;
                    this.listStateList[3].num = result1.data.waitSellerDeliveryNums;
                    this.listStateList[4].num = result1.data.successNums;
                    this.listStateList[5].num = result1.data.refuseNums;
                }
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		search () {
			this.page.pageNo = 1
			this.searchData.isDownload = false;    //下载文件标记
			if (!this.page.total) {
				this.page.size = 20
			}
			this.getList()
		},
		reset () {
			let searchData = {
				afterSaleCode: '',    //售后单号
				goodsCode: '',  //商品编码
				orderNo:'',//订单编号
				goodsName: '',  //商品名称
				startDate: '',  //开始时间
				endDate: '',    //结束时间
				applyReason: null, //申请原因
				cargoStatus: null,  //发货状态
				status: null,    //售后状态: 1、待审单 2、待审核  3、待下发售后地址 4、待买家发货 5、待卖家收货 6、待打款 7、售后成功 8、售后关闭 9、待商家发货
				minRefundMoney: null,    //最小退款金额
				maxRefundMoney: null,    //最小退款金额
				searchType: '0', //订单类型
			};
			this.searchTime = '';
			this.searchData = searchData;
			this.search();
		},
		// 导出
		async groupExport () {
            this.exportLoading = true;
			let data = this.searchData;
			data.isDownload = true;    //下载文件标记
            await apiList.ordersupportExchangeIndexExport(data);
            this.$message({
                type: 'success',
                message: '导出成功，请稍后至下载中心查看!'
            });
            this.exportLoading = false;
		},
		//跳转至详情
		handleDetailShow (row) {
            let params = {
                id: row.afterSaleId
            }
			if (row.type == 3) {
				// this.$router.push({
                //     path: '/order/refundAfterSale/exChangeRefundDetails?id=' + row.afterSaleId
                    
                // });
                this.publicToDetails(params,'/order/refundAfterSale/exChangeRefundDetails',this);
			} else {
                this.publicToDetails(params,'/order/refundAfterSale/refundDetails',this);
				// this.$router.push({
				// 	path: '/order/refundAfterSale/refundDetails?id=' + row.afterSaleId
				// });
			}
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.page.size = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.page.pageNo = val;
			this.getList();
		},
		async handleAfterBack(row) {
			let data = {
				afterSaleId: row.afterSaleId
			}
			let res = await afterSaleBack(data);
			if (res.success) {
				// this.searchData.pageNo = 1
				// this.searchData.size = 20
				this.getList();
				this.$message({
					type: 'success',
					message: '回滚成功'
				});
			} else {
				this.$message({
					type: 'error',
					message: res.alertMsg
				});
			}
		}
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background-color: #fff;
}

.input-item {
	margin-left: 5px;
}

.option-item {
	/deep/.el-input {
		width: 150px !important;
	}
}

.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

/deep/.el-table__fixed-body-wrapper {
	background: #ffffff;
}
/deep/.el-table__fixed-header-wrapper {
	background: #f8f8f9;
}
</style>
